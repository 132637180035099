// src/App.js
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig'; // Certifique-se de que este arquivo está configurado corretamente
import ContactList from './components/ContactList';
import ChatWindow from './components/ChatWindow';
import { colors } from './components/styles';
import Login from './components/Login'; // Componente de login que você vai criar (ou já criou)

const AppContainer = styled.div`
  display: flex;
  height: 100%;
  background-color: ${colors.background};
  font-family: 'Roboto', sans-serif;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

function App() {
  const [selectedContact, setSelectedContact] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [messages, setMessages] = useState({});
  const [user, setUser] = useState(null); // Estado para o usuário autenticado
  const [loading, setLoading] = useState(true); // Estado para o carregamento
  const ws = useRef(null);

  const isMobile = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    // Verificar se o usuário está autenticado
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log(user)
        setUser(user); // Se o usuário está autenticado, definimos o usuário
      } else {
        setUser(null); // Se não houver usuário, resetamos
      }
      setLoading(false); // Termina o carregamento
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!user) return; // Só conecte ao WebSocket se o usuário estiver autenticado

    const connectWebSocket = () => {
      ws.current = new WebSocket(
        'wss://bcnj15swl1.execute-api.sa-east-1.amazonaws.com/production/?userId=test-user'
      );

      ws.current.onopen = () => {
        console.log('WebSocket connected');
      };

      ws.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        handleIncomingMessage(data);
      };

      ws.current.onclose = (e) => {
        console.log('WebSocket closed. Reconnecting in 1 second...', e.reason);
        setTimeout(() => {
          connectWebSocket();
        }, 1000);
      };

      ws.current.onerror = (err) => {
        console.error('WebSocket error observed:', err.message);
        ws.current.close();
      };
    };

    connectWebSocket();

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleIncomingMessage = (data) => {
    const { id, from, type, content } = data;

    // Update messages for the contact
    setMessages((prevMessages) => {
      const contactMessages = prevMessages[from] || [];
      return {
        ...prevMessages,
        [from]: [...contactMessages, { id, from, type, text: content }],
      };
    });

    // Update contacts list
    setContacts((prevContacts) => {
      const existingContact = prevContacts.find(
        (contact) => contact.identity === from
      );
      if (existingContact) {
        // Update last message and move to top
        const updatedContact = {
          ...existingContact,
          lastMessage: content,
        };
        const filteredContacts = prevContacts.filter(
          (contact) => contact.identity !== from
        );
        return [updatedContact, ...filteredContacts];
      } else {
        // Add new contact
        const newContact = {
          name: from,
          identity: from,
          lastMessage: content,
        };
        return [newContact, ...prevContacts];
      }
    });
  };

  // Mostrar o carregamento enquanto verifica a autenticação
  if (loading) {
    return <div>Carregando...</div>;
  }

  // Se o usuário não estiver autenticado, exibir o componente de login
  if (!user) {
    return <Login />;
  }

  return (
    <AppContainer>
      <ContactList
        isVisible={!(isMobile && selectedContact)}
        onSelectContact={setSelectedContact}
        contacts={contacts}
        setContacts={setContacts}
      />
      {selectedContact && (
        <ChatWindow
          contact={selectedContact}
          onBack={() => setSelectedContact(null)}
          messages={messages[selectedContact.identity] || []}
          setMessages={setMessages}
        />
      )}
    </AppContainer>
  );
}

export default App;
