import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Header from './Header';
import Message from './Message';
import MessageInput from './MessageInput';
import { colors } from './styles';
import { v4 as uuidv4 } from 'uuid';
import { FaChevronDown } from 'react-icons/fa';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: ${colors.background};
  position: relative;
`;

const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  width: 77%;
  max-width: 100%;
  background-color: white;
  z-index: 10;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const MessagesContainer = styled.div`
  flex: 1;
  background-color: ${colors.background};
  background-image: url('https://static.whatsapp.net/rsrc.php/v3/yl/r/gi_DckOUM5a.png');
  background-repeat: repeat;
  background-size: cover;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  margin-bottom: 60px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
`;

const MessageInputContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 77%;
  max-width: 100%;
  background-color: white;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ScrollToBottomButton = styled.button`
  position: fixed;
  bottom: 80px;
  right: 20px;
  background-color: ${colors.header};
  border: none;
  border-radius: 50%;
  padding: 10px;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  z-index: 100;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
`;

function ChatWindow({ contact, onBack, messages, setMessages }) {
  const [loading, setLoading] = useState(false);
  const [lastMessageId, setLastMessageId] = useState(null);
  const [lastStorageDate, setLastStorageDate] = useState(null);
  const [endReached, setEndReached] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const apiKey = 'Key bXlkZWNoYXRkZXY6M2xacjgxY2xMTnFrWkZqWFJlUEU=';

  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    setShowScrollButton(false);
  };

  const fetchMessages = async (newContact, reset = false) => {
    if (loading) return;
    setLoading(true);

    const previousScrollHeight = messagesContainerRef.current.scrollHeight;

    try {
      const messageIdParam = lastMessageId ? `&messageId=${lastMessageId}` : '';
      const storageDateParam = lastStorageDate ? `&storageDate=${lastStorageDate}` : '';

      const response = await fetch('https://myde.http.msging.net/commands', {
        method: 'POST',
        headers: {
          Authorization: apiKey,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: uuidv4(),
          method: 'get',
          uri: `/threads-merged/${encodeURIComponent(
            newContact.identity
          )}?$take=20&direction=desc&refreshExpiredMedia=true${messageIdParam}${storageDateParam}&getFromOriginator=true`,
          to: 'postmaster@threads.msging.net',
          from: 'mydechatdev@msging.net',
        }),
      });

      const data = await response.json();
      if (data.resource && data.resource.items) {
        const fetchedMessages = data.resource.items
          .filter((item) => item.type === 'text/plain')
          .map((item) => ({
            id: item.id,
            from: item.direction === 'received' ? 'cliente' : 'atendente',
            text: item.content,
            date: item.date,
            storageDate: item.storageDate,
          }));

        if (data.resource.items.length < 20) {
          setEndReached(true);
        }

        const orderedMessages = fetchedMessages.reverse();

        setMessages((prevMessages) => {
          const contactMessages = prevMessages[contact.identity] || [];
          const updatedMessages = reset
            ? orderedMessages
            : [...orderedMessages, ...contactMessages];
          return {
            ...prevMessages,
            [contact.identity]: updatedMessages,
          };
        });

        if (data.resource && data.resource.items) {
          const lastMessage = data.resource.items[data.resource.items.length - 1];
          setLastMessageId(lastMessage.id);
          setLastStorageDate(lastMessage.date);
        }

        if (!reset) {
          setTimeout(() => {
            messagesContainerRef.current.scrollTop =
              messagesContainerRef.current.scrollHeight - previousScrollHeight;
          }, 100);
        } else {
          scrollToBottom();
        }
      }
    } catch (error) {
      console.error('Erro ao buscar mensagens:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLastMessageId(null);
    setLastStorageDate(null);
    setEndReached(false);
    fetchMessages(contact, true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact.identity]);

  const handleScroll = (e) => {
    const isAtBottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    setShowScrollButton(!isAtBottom);

    if (e.target.scrollTop === 0 && !loading && !endReached) {
      fetchMessages(contact);
    }
  };

  const handleSendMessage = (newMessage) => {
    setMessages((prevMessages) => {
      const contactMessages = prevMessages[contact.identity] || [];
      return {
        ...prevMessages,
        [contact.identity]: [...contactMessages, newMessage],
      };
    });

    const isAtBottom =
      messagesContainerRef.current.scrollHeight -
      messagesContainerRef.current.scrollTop ===
      messagesContainerRef.current.clientHeight;

    if (isAtBottom) {
      scrollToBottom();
    }
  };

  return (
    <Container>
      <HeaderContainer>
        <Header contact={contact} onBack={onBack} />
      </HeaderContainer>
      <MessagesContainer ref={messagesContainerRef} onScroll={handleScroll}>
        {messages.map((msg, index) => (
          <Message key={index} from={msg.from} text={msg.text} />
        ))}
        <div ref={messagesEndRef} />
      </MessagesContainer>
      <MessageInputContainer>
        <MessageInput
          contactIdentity={contact.identity}
          onSendMessage={handleSendMessage}
        />
      </MessageInputContainer>
      <ScrollToBottomButton isVisible={showScrollButton} onClick={scrollToBottom}>
        <FaChevronDown />
      </ScrollToBottomButton>
    </Container>
  );
}

export default ChatWindow;
