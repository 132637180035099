// src/components/Header.js

import React from 'react';
import styled from 'styled-components';
import { colors } from './styles';
import { FaArrowLeft, FaSearch, FaEllipsisV } from 'react-icons/fa';

const Container = styled.div`
  background-color: ${colors.header};
  padding: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: ${colors.textSecondary};
  font-size: 20px;
  margin-right: 10px;
  cursor: pointer;

  @media (min-width: 769px) {
    display: none;
  }
`;

const Avatar = styled.div`
  background-color: ${colors.primary};
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-right: 10px;
`;

const ContactName = styled.div`
  flex: 1;
  font-weight: bold;
  color: ${colors.textPrimary};
`;

const Icons = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-left: 15px;
    color: ${colors.textSecondary};
    cursor: pointer;
  }
`;

function Header({ contact, onBack }) {
  return (
    <Container>
      <BackButton onClick={onBack}>
        <FaArrowLeft />
      </BackButton>
      <Avatar>{contact.name.charAt(0)}</Avatar>
      <ContactName>{contact.name}</ContactName>
      <Icons>
        <FaSearch />
        <FaEllipsisV />
      </Icons>
    </Container>
  );
}

export default Header;
