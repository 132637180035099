import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from './styles';
import { FaSmile, FaPaperPlane, FaPaperclip, FaMicrophone } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid'; // Para gerar um unique-id

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: ${colors.header};
  border-top: 1px solid #ddd;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  color: ${colors.textSecondary};
  font-size: 20px;
  margin: 0 10px;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const Input = styled.input`
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 20px;
  background-color: #fff;
  font-size: 14px;
  outline: none;
`;

function MessageInput({ contactIdentity, onSendMessage }) {
  const [message, setMessage] = useState('');

  const handleSend = async () => {
    if (!message.trim()) return; // Evita envio de mensagens vazias

    const apiKey = 'Key bXlkZWNoYXRkZXY6M2xacjgxY2xMTnFrWkZqWFJlUEU='; // Substitua pelo seu API key

    try {
      const response = await fetch('https://myde.http.msging.net/messages', {
        method: 'POST',
        headers: {
          Authorization: apiKey,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: uuidv4(),
          to: contactIdentity,
          type: 'text/plain',
          content: message,
        }),
      });

      if (response.ok) {
        const newMessage = {
          id: uuidv4(),
          from: 'atendente',
          text: message,
          date: new Date().toISOString(),
        };

        onSendMessage(newMessage);
        setMessage('');
      } else {
        console.error('Erro ao enviar mensagem:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao enviar mensagem:', error);
    }
  };

  return (
    <Container>
      <IconButton>
        <FaSmile />
      </IconButton>
      <Input
        type="text"
        placeholder="Digite uma mensagem"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') handleSend();
        }}
      />
      <IconButton>
        <FaPaperclip />
      </IconButton>
      <IconButton onClick={handleSend}>
        {message ? <FaPaperPlane /> : <FaMicrophone />}
      </IconButton>
    </Container>
  );
}

export default MessageInput;
