// src/components/Login.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { getFirestore, doc, getDoc } from 'firebase/firestore'; // Firestore

const firestore = getFirestore();

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #e6f7ff; /* fundo azul claro */
  padding: 20px; /* padding para dispositivos menores */
`;

const LoginBox = styled.div`
  background-color: white;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px; /* largura máxima para telas grandes */
`;

const Title = styled.h2`
  color: #007bff;
  font-size: 24px;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  background-color: #f8f9fa;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  width: 100%;

  &:hover {
    background-color: #0056b3;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
`;

const SwitchButton = styled.button`
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    text-decoration: underline;
  }
`;

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isRegistering, setIsRegistering] = useState(false); // Estado para alternar entre login e registro

  // Função para checar se o email está na whitelist
  const checkWhitelist = async (email) => {
    try {
      const docRef = doc(firestore, 'whitelistedEmails', email);
      const docSnap = await getDoc(docRef);

      return docSnap.exists(); // Retorna true se o email estiver na whitelist
    } catch (error) {
      console.error('Erro ao verificar whitelist:', error);
      return false;
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const userEmail = userCredential.user.email;

      const isWhitelisted = await checkWhitelist(userEmail);
      if (isWhitelisted) {
        alert('Acesso concedido!');
      } else {
        setError('Seu email não tem permissão para acessar o sistema.');
      }
    } catch (error) {
      setError('Erro ao fazer login. Verifique suas credenciais.');
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      // Primeiro checa se o email está na whitelist
      const isWhitelisted = await checkWhitelist(email);
      if (!isWhitelisted) {
        setError('Seu email não está na lista de permissões para se registrar.');
        return;
      }

      // Se o email estiver na whitelist, faz o registro
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userEmail = userCredential.user.email;
      console.log(userEmail);
      alert('Usuário registrado com sucesso!');
    } catch (error) {
      setError('Erro ao registrar. Verifique os dados inseridos.');
    }
  };

  return (
    <LoginContainer>
      <LoginBox>
        <Title>{isRegistering ? 'Registrar' : 'Login'} no MydeChat</Title>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <form onSubmit={isRegistering ? handleRegister : handleLogin}>
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            type="password"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button type="submit">{isRegistering ? 'Registrar' : 'Entrar'}</Button>
        </form>
        <SwitchButton onClick={() => setIsRegistering(!isRegistering)}>
          {isRegistering ? 'Já tem uma conta? Faça login' : 'Não tem uma conta? Registre-se'}
        </SwitchButton>
      </LoginBox>
    </LoginContainer>
  );
};

export default Login;
